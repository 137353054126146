<template>
  <div class="page page-support support">
    <b-col class="py-3">
      <Breadcrumb :items="[{label: $t('Support')}]"/>
      <b-row class="header text-center py-3 mb-4">
        <b-col>
          <div v-if="account" class="username">{{$t("Hi {0}!", [account.displayName])}}</div>
          <h1>{{$t("Can we help you")}}</h1>
        </b-col>
      </b-row>
      <LoadingPanel v-if="loading" />
      <div class="menus" v-else-if="menus && menus.length">
        <b-row>
          <b-col cols="6" v-for="(menu, index) in menus" :key="index" class="mb-3">
            <a href="#" class="menu-item" @click.prevent="onMenuItemClick(menu)">
              <img :src="menu.imageUrl.getUrl()" :alt="menu.label"/>
              <label>{{menu.label}}</label>
            </a>
          </b-col>
        </b-row>
      </div>
      <NotFoundPanel v-else/>
      <div class="text-center" v-html="$t('Support note')">
      </div>
    </b-col>
  </div>
</template>

<script>
import supportService from "@/services/supportService";
export default {
  name: "Index",
  data() {
    return {
      loading: true,
      account: null,
      menus: null
    }
  },
  async created() {
    const store = this.$store;
    this.account = store.getters.account;
    let menus = store.getters.supportMenus;
    if (menus) {
    }
    else {
      const response = await supportService.index();
      if (response && !response.error) {
        menus = response.data.menus.supports;
        await store.dispatch("setSupportMenus", menus);
      }
    }

    this.menus = menus || [];
    this.loading = false;
  },
  methods: {
    onMenuItemClick(item) {
      this.$redirectTo(item.redirect);
    }
  }
}
</script>

<style scoped lang="scss">
.support {
  .header {
    display: flex;
    justify-items: center;
    align-items: center;
    min-height: 90px;
    background: #ee4623;
    background-image: url("../../assets/images/icon/icon_per.svg");
    background-position: bottom right;
    background-repeat: no-repeat;
    color: #ffffff;

    .username {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      opacity: .8;
      margin-bottom: 12px;
    }

    h1 {
      font-size: 20px;
      margin-bottom: 0;
      font-weight: 700;
      margin-bottom: 0;
    }
  }

  .menus {
    .menu-item {
      img {
        width: 100%;
        margin-bottom: 5px;
      }

      label {
        display: block;
        text-align: center;
        font-size: 16px;
        text-transform: uppercase;
      }
    }
  }
}
</style>
